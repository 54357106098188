import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styles } from "./FacilityEditor.styles";
import TextField from "@material-ui/core/TextField";
import { Grid, Button, Box, ListSubheader } from "@material-ui/core";
import moment from "moment-timezone";
import MenuItem from "@material-ui/core/MenuItem";
import { Formik, Form, Field } from "formik";
import Title from "../../Title";
import * as Yup from "yup";
import MuiPhoneNumber from "material-ui-phone-number";
import FacilityService from "../../../services/FacilityService";
import settingsService from "../../../services/SettingsService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import apiClient from "../../../auth/apiClient";
import InputField from "../InputField";
import {
  transformFacilityDataIngress,
  transformFacilityDataEgress
} from "./utilities";
import { useSelector, useDispatch } from "react-redux";
import {
  entityUpdate,
  onboardFacility
} from "../../../state/slices/entities";
import {
  updateEntity,
  onboardNewFacility
} from "../../../state/slices/CoreEntity";
import { useCountry } from '../../../hooks/useCountry';
import clsx from "clsx";
import { COUNTRY_CODES } from '../../../constants/index';
import CountryService from '../../../services/CountryService';
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const countryService = new CountryService(apiClient);
const facilityService = new FacilityService(apiClient);
const settingService = new settingsService(apiClient);

export const ValidationSchema = Yup.object().shape({
  FacilityName: Yup.string()
    .min(3)
    .max(100, "Cannot exceed 100 characters")
    .required("Required"),
  TimeZone: Yup.string(),
  FacilityAddress1: Yup.string().required("Required"),
  FacilityAddress2: Yup.string(),
  FacilityCity: Yup.string().required("Required"),
  FacilitySubdivisionID: Yup.number().required("Required"), 
  FacilityCountryID: Yup.number().required("Required"),
  FacilityZip: Yup.string().required("Required"),
  PrimaryFirstName: Yup.string().required("Required"),
  PrimaryLastName: Yup.string().required("Required"),
  PrimaryAddress1: Yup.string().required("Required"),
  PrimaryAddress2: Yup.string(),
  PrimaryCity: Yup.string().required("Required"),
  PrimarySubdivisionID: Yup.number().required("Required"),
  PrimaryCountryID: Yup.number().required("Required"),
  PrimaryZip: Yup.string().required("Required"),
  PrimaryPhone1: Yup.string().required("Required"),
  PrimaryPhone2: Yup.string(),
  PrimaryEMailAddress: Yup.string()
    .email("Invalid email")
    .required("Required"),
  SecondaryFirstName: Yup.string(),
  SecondaryLastName: Yup.string(),
  SecondaryAddress1: Yup.string(),
  SecondaryAddress2: Yup.string(),
  SecondaryCity: Yup.string(),
  SecondarySubdivisionID: Yup.number(),
  SecondaryZip: Yup.string(),
  SecondaryPhone1: Yup.string(),
  SecondaryPhone2: Yup.string(),
  SecondaryEMailAddress: Yup.string().email("Invalid email")
});

const FacilityEditor = ({
  entityID,
  parentEntityID,
  onDeleteComplete, // this prop is never being used I wonder if this is even needed? @jason?
  onSubmitComplete,
  onCancel
}) => {
  const currentUser = useSelector(state => state.user);

  const useCoreEntitySlice = useCoreEntityContext();

  const dispatch = useDispatch();
  const { countries } = useCountry();

  const [facilityData, setFacilityData] = useState({
    parentEntityID: parentEntityID,
    FacilityName: "",
    TimeZone: moment.tz.guess() ?? "",
    FacilityAddress1: "",
    FacilityAddress2: "",
    FacilityCountryID: "",
    FacilityCity: "",
    FacilityState: "",
    FacilitySubdivisionID: "",
    FacilityZip: "",
    PrimaryFirstName: "",
    PrimaryLastName: "",
    PrimaryAddress1: "",
    PrimaryAddress2: "",
    PrimaryCountryID: "",
    PrimaryCity: "",
    PrimaryState: "",
    PrimarySubdivisionID: "",
    PrimaryZip: "",
    PrimaryPhone1: "",
    PrimaryPhone2: "",
    PrimaryEMailAddress: "",
    SecondaryFirstName: "",
    SecondaryLastName: "",
    SecondaryAddress1: "",
    SecondaryAddress2: "",
    SecondaryCountryID: "",
    SecondaryCity: "",
    SecondarySubdivisionID: "",
    SecondaryState: "",
    SecondaryZip: "",
    SecondaryPhone1: "",
    SecondaryPhone2: "",
    SecondaryEMailAddress: ""
  });

  const style = styles();
  const isEditMode = entityID != null;
  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog } = useConfirmationDialog();
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [facilitySubdivisions, setFacilitySubdivisions] = useState();
  const [facilitySubdivisionLabel, setFacilitySubdivisionLabel] = useState('State/Province');
  const [primarySubdivisions, setPrimarySubdivisions] = useState();
  const [primarySubdivisionLabel, setPrimarySubdivisionLabel] = useState('State/Province');
  const [secondarySubdivisions, setSecondarySubdivisions] = useState();
  const [secondarySubdivisionLabel, setSecondarySubdivisionLabel] = useState('State/Province');

  const buildTimezoneOptions = (header, options) => {
    var output = [];
    output.push(
      <ListSubheader
        disableSticky
        key={`timezone-header-${header}`}
        className={clsx(style.timezoneHeader)}
        // clicking subheader initiates a select action
        // https://github.com/mui/material-ui/issues/18200
        // TODO: this might be messing with accessability/keyboard navigation FYI
        onClickCapture={(e) => e.stopPropagation()}
        >
        {header}
      </ListSubheader>
    )
    for (var i = 0; i < options.length; i++) {
      output.push(
        <MenuItem value={options[i]} key={options[i]}>
          {options[i].replaceAll("_", " ")}
        </MenuItem>
      );
    }

    return output;
  };

  useEffect(() => {
    var zoneOptions = [];
    {countries && countries.length > 0 && countries.map((country) => {
      var zones = moment.tz.zonesForCountry(country.alpha2);
      if (zones && zones.length > 0) {
        zoneOptions = zoneOptions.concat(buildTimezoneOptions(country.countryName, zones));
      }
    })}

    setTimezoneOptions(zoneOptions);
  }, [countries]);

  useEffect(() => {
    const work = async () => {
      let response;
      try {
        response = await facilityService.getEntity(
          //TODO: POST1A THIS SHOULD NOT SKIP REDUX
          parentEntityID, //SHOULD BE NORMALIZED TO READ ENTITY TREE
          entityID
        );
      } catch (err) {
        enqueueSnackbar("Failed to retrieve facility info", {
          variant: "error",
          tag: "FailedToRetriveFacility"
        });
        return;
      }

      const transformed = transformFacilityDataIngress(response.data);
      setFacilityData(prev => ({
        ...prev,
        ...transformed,
        TimeZone: response.data.timeZone ?? "",
        parentEntityID: response.data.parentEntityID,
        facilityID: response.data.facilityID
      }));
    };

    if (entityID && parentEntityID && isEditMode) {
      work();
    }
  }, [entityID, parentEntityID]);

  function phonefield(name, label, props) {
    return (
      <Field name={name}>
        {form => (
          <>
            <MuiPhoneNumber
              className={style[name]}
              defaultCountry={"us"}
              regions={["north-america"]}
              onChange={e => props.setFieldValue(name, e)}
              value={props.values[name]}
              label={label}
              data-id={name}
              data-testid={name}
              disableAreaCodes
              disableCountryCode
              variant="outlined"
              error={props.errors && props.errors[name] && props.touched[name]}
              helperText={props.errors && props.errors[name]}
              onlyCountries={countries.map((country) => country.alpha2.toLowerCase())}
              excludeCountries={['pm', 'bm']}
            />
            </>
        )}
      </Field>
    );
  }

  function textfield(name, label, props, disabled = false) {
    return (
      <InputField
        {...props}
        labelName={label}
        fieldName={name}
        component={TextField}
        disabled={disabled}
      />
    );
  }

  const createOrUpdateEntity = async values => {
    setIsSaveDisabled(true);
    const transformed = transformFacilityDataEgress(values);
    transformed.parentEntityID = parentEntityID;

    let _payload = {};
    if (isEditMode) {
      transformed.facilityID = values.facilityID;
      _payload.entity = transformed;
      _payload.path = "facility";
    } else _payload = transformed;
    const result = isEditMode
      ? await dispatch(useCoreEntitySlice ? updateEntity(_payload) : entityUpdate(_payload))
      : await dispatch(useCoreEntitySlice ? onboardNewFacility(_payload) : onboardFacility(_payload));
    if (result.error) {
      enqueueSnackbar(
        `We encountered an error while ${isEditMode ? "updating" : "creating"
        } the facility`,
        { variant: "error", tag: "FailedToUpdateFacility" }
      );
      return;
    }
    if (transformed.TimeZone)
      await settingService.updateSetting(isEditMode ? entityID : result.payload?.facilityID, {
        name: "setting.timezone",
        value: transformed.TimeZone,
        userName: currentUser.EmailAddress
      });
    onSubmitComplete();
  };

  const getSubdivisonLabelValue = (countryId) => {
    if (countryId === COUNTRY_CODES.UnitedStates) {
      return 'State';
    } else if (countryId === COUNTRY_CODES.Canada) {
      return 'Province';
    } 
  }

  const onFacilityCountryChanged = async (formikProps, countryId) => {
    const { setFieldValue } = formikProps;

    try {
      const response = await countryService.getSubdivisions(countryId);

      if (formikProps.values['FacilitySubdivisionID']){
        setFieldValue('FacilitySubdivisionID', '');
      }

      setFacilitySubdivisions(response.data);
      setFacilitySubdivisionLabel(getSubdivisonLabelValue(countryId))
      setFieldValue('FacilityCountryID', countryId);
    } catch (error) {
      enqueueSnackbar(
        `Failed to get facility subdivisons: ${error}`,
        { variant: "error", tag: "FailedToGetFacilitySubdivisions" }
      );
    }
  }

  const onPrimaryCountryChanged = async (formikProps, countryId) => {
    const { setFieldValue } = formikProps;

    try {
      const response = await countryService.getSubdivisions(countryId);

      if (formikProps.values['PrimarySubdivisionID']){
        setFieldValue('PrimarySubdivisionID', '');
      }

      setPrimarySubdivisions(response.data);
      setPrimarySubdivisionLabel(getSubdivisonLabelValue(countryId))
      setFieldValue('PrimaryCountryID', countryId);
    } catch (error) {
      enqueueSnackbar(
        `Failed to get primary subdivisons: ${error}`,
        { variant: "error", tag: "FailedToGetPrimarySubdivisions" }
      );
    }
  }

  const onSecondaryCountryChanged = async (formikProps, countryId) => {
    const { setFieldValue } = formikProps;

    try {
      const response = await countryService.getSubdivisions(countryId);

      if (formikProps.values['SecondarySubdivisionID']){
        setFieldValue('SecondarySubdivisionID', '');
      }

      setSecondarySubdivisions(response.data);
      setSecondarySubdivisionLabel(getSubdivisonLabelValue(countryId))
      setFieldValue('SecondaryCountryID', countryId);
    } catch (error) {
      enqueueSnackbar(
        `Failed to get secondary subdivisons: ${error}`,
        { variant: "error", tag: "FailedToGetSecondarySubdivisions" }
      );
    }
  }

  useEffect(() => {
    const getSubdivisions = async (countryId) => {
      try {
        const response = await countryService.getSubdivisions(countryId);

        setFacilitySubdivisions(response.data)
        setFacilitySubdivisionLabel(getSubdivisonLabelValue(countryId))
      } catch (error) {
        enqueueSnackbar(
          `Failed to get facility subdivisons: ${error}`,
          { variant: "error", tag: "FailedToGetFacilitySubdivisions" }
        );
      }
    }
    if (facilityData.FacilityCountryID) {
      getSubdivisions(facilityData.FacilityCountryID)
    }
  }, [facilityData.FacilityCountryID])

  useEffect(() => {
    const getSubdivisions = async (countryId) => {
      try {
        const response = await countryService.getSubdivisions(countryId);

        setPrimarySubdivisions(response.data)
        setPrimarySubdivisionLabel(getSubdivisonLabelValue(countryId))
      } catch (error) {
        enqueueSnackbar(
          `Failed to get primary subdivisons: ${error}`,
          { variant: "error", tag: "FailedToGetPrimarySubdivisions" }
        );
      }
    }
    if (facilityData.PrimaryCountryID) {
      getSubdivisions(facilityData.PrimaryCountryID)
    }
  }, [facilityData.PrimaryCountryID])

  useEffect(() => {
    const getSubdivisions = async (countryId) => {
      try {
        const response = await countryService.getSubdivisions(countryId);

        setSecondarySubdivisions(response.data)
        setSecondarySubdivisionLabel(getSubdivisonLabelValue(countryId))
      } catch (error) {
        enqueueSnackbar(
          `Failed to get secondary subdivisons: ${error}`,
          { variant: "error", tag: "FailedToGetSecondarySubdivisions" }
        );
      }
    }
    if (facilityData.SecondaryCountryID) {
      getSubdivisions(facilityData.SecondaryCountryID)
    }
  }, [facilityData.SecondaryCountryID])

  return (
    <>
      <Grid classname={clsx("facility-title")} data-testid="title" container spacing={2}>
        <Grid item xs={9}>
          <Title>{isEditMode ? "Edit Facility" : "Create Facility"}</Title>
        </Grid>
      </Grid>
      <Formik
        onSubmit={async values => {
          values = { ...values, FacilityName: values.FacilityName?.trim() };
          await createOrUpdateEntity(values);
        }}
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={facilityData}
        validationSchema={ValidationSchema}
      >
        {(formikProps) => (
          <Form className={clsx(style.root, "facility-editor")}>
            <div className={style.top}>
              {textfield(
                "FacilityName",
                "Facility Name",
                formikProps,
                !currentUser.isAdmin
              )}
              <div className={style.btnGroup}>
                <Button
                  key="savebutton"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSaveDisabled}
                  onClick={() => {
                    const { submitForm } = formikProps;
                    submitForm();
                  }}
                  className={clsx(style.btn, "save")}
                >
                  Save
                </Button>
                <Button
                  key="cancelbutton"
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                  className={clsx(style.btn, "cancel")}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <Box className={style.grouping} borderColor="grey.main" border={1}>
              Facility Address
              <Field
                id="timezones"
                data-id="timezone-select"
                component={TextField}
                labelId="TimeZoneLabel"
                name="TimeZone"
                label="Time Zone *"
                variant="outlined"
                defaultValue=""
                value={facilityData.TimeZone ?? ""}
                select
                SelectProps={{
                  SelectDisplayProps: { "data-testid": "timezone-select" }
                }}
                className={style.timeZone}
                onChange={(e, selected) => {
                  facilityData.TimeZone = selected.props.value ?? "";
                  const { setFieldValue } = formikProps;
                  setFieldValue("TimeZone", selected.props.value);
                }}
                disabled={!currentUser.isAdmin}
              >
                {timezoneOptions}
              </Field>
              {textfield(
                "FacilityAddress1",
                "Address 1 *",
                formikProps,
                !currentUser.isAdmin
              )}
              {textfield(
                "FacilityAddress2",
                "Address 2",
                formikProps,
                !currentUser.isAdmin
              )}
              <Field
                id='facilitycountry'
                data-id="facility-country-select"
                data-testid='facility-country-textfield'
                component={TextField}
                name='FacilityCountryID'
                labelId='FacilityCountryLabel'
                label='Country *'
                variant='outlined'
                value={formikProps.values['FacilityCountryID']}
                select
                SelectProps={{
                  SelectDisplayProps: { 'data-testid': 'facility-country-select' }
                }}
                error={Boolean(formikProps.errors?.FacilityCountryID)}
                helperText={formikProps.errors?.FacilityCountryID}
                FormHelperTextProps={{
                  'data-testid': 'facility-country-helpertext'
                }}
                onChange={(event, selected) => {        
                  onFacilityCountryChanged(formikProps, selected.props.value);           
                }}
                size='small'
                disabled={!currentUser.isAdmin}
              >
                {countries && countries.length > 0 && countries.map((country) => {
                  return (
                    <MenuItem className={clsx(`${countries.countryName}`)} key={country.countryID} value={country.countryID}>
                      {country.countryName}
                    </MenuItem>
                  );
                })}
              </Field>
              {textfield(
                "FacilityCity",
                "City *",
                formikProps,
                !currentUser.isAdmin
              )}
              <Field
                id='facilitysubdivision'
                data-id="facility-subdivision-select"
                data-testid='facility-subdivision-textfield'
                component={TextField}
                name='FacilitySubdivisionID'
                labelId='FacilitySubdivisionLabel'
                label={`${facilitySubdivisionLabel} *`}
                variant='outlined'
                value={formikProps.values['FacilitySubdivisionID']}
                select
                SelectProps={{
                  SelectDisplayProps: { 'data-testid': 'facility-subdivision-select' }
                }}
                error={Boolean(formikProps.errors?.FacilitySubdivisionID)}
                helperText={formikProps.errors?.FacilitySubdivisionID}
                FormHelperTextProps={{
                  'data-testid': 'facility-subdivision-helpertext'
                }}
                onChange={(event, selected) => {
                  const { setFieldValue } = formikProps;
                  setFieldValue('FacilitySubdivisionID', selected.props.value);
                }}
                size='small'
                disabled={!currentUser.isAdmin}
              >
                {formikProps.values['FacilityCountryID'] && facilitySubdivisions && facilitySubdivisions.length > 0 && facilitySubdivisions.map((facilitySubdivision) => {
                  return (
                    <MenuItem 
                      className={clsx(`${facilitySubdivision.subdivisionName}`)} 
                      key={facilitySubdivision.subdivisionID} 
                      value={facilitySubdivision.subdivisionID} 
                      fieldName="FacilitySubdivisionID"
                    >
                      {facilitySubdivision.subdivisionName}
                    </MenuItem>
                  );
                })}
                {!formikProps.values['FacilityCountryID'] &&
                  <MenuItem className={clsx(`select-a-country`)} value='' fieldName="FacilitySubdivisionID">
                    Select a country
                  </MenuItem>
                }
              </Field>
              {textfield(
                "FacilityZip",
                "Zip *",
                formikProps,
                !currentUser.isAdmin
              )}
            </Box>

            <div className={style.inline}>
              <Box
                className={style.grouping}
                borderColor="grey.main"
                border={1}
              >
                Primary Contact
                {textfield("PrimaryFirstName", "First Name *", formikProps)}
                {textfield("PrimaryLastName", "Last Name *", formikProps)}
                {textfield("PrimaryAddress1", "Address 1 *", formikProps)}
                {textfield("PrimaryAddress2", "Address 2", formikProps)}
                <Field
                  id='primarycountry'
                  data-id="primary-country-select"
                  data-testid='primary-country-textfield'
                  component={TextField}
                  name='PrimaryCountryID'
                  labelId='PrimaryCountryLabel'
                  label='Country *'
                  variant='outlined'
                  value={formikProps.values['PrimaryCountryID']}
                  select
                  SelectProps={{
                    SelectDisplayProps: { 'data-testid': 'primary-country-select' }
                  }}
                  error={Boolean(formikProps.errors?.PrimaryCountryID)}
                  helperText={formikProps.errors?.PrimaryCountryID}
                  FormHelperTextProps={{
                    'data-testid': 'primary-country-helpertext'
                  }}
                  onChange={(event, selected) => {
                    onPrimaryCountryChanged(formikProps, selected.props.value);
                  }}
                  size='small'
                >
                  {countries && countries.length > 0 && countries.map((country) => {
                    return (
                      <MenuItem className={clsx(`${countries.countryName}`)} key={country.countryID} value={country.countryID}>
                        {country.countryName}
                      </MenuItem>
                    );
                  })}
                </Field>
                {textfield("PrimaryCity", "City *", formikProps)}
                <Field
                  id='primarysubdivision'
                  data-id="primary-subdivision-select"
                  data-testid='primary-subdivision-textfield'
                  component={TextField}
                  name='PrimarySubdivisionID'
                  labelId='PrimarySubdivisionLabel'
                  label={`${primarySubdivisionLabel} *`}
                  variant='outlined'
                  value={formikProps.values['PrimarySubdivisionID']}
                  select
                  SelectProps={{
                    SelectDisplayProps: { 'data-testid': 'primary-subdivision-select' }
                  }}
                  error={Boolean(formikProps.errors?.PrimarySubdivisionID)}
                  helperText={formikProps.errors?.PrimarySubdivisionID}
                  FormHelperTextProps={{
                    'data-testid': 'primary-subdivision-helpertext'
                  }}
                  onChange={(event, selected) => {
                    const { setFieldValue } = formikProps;
                    setFieldValue('PrimarySubdivisionID', selected.props.value);
                  }}
                  size='small'
                >
                  {formikProps.values['PrimaryCountryID'] && primarySubdivisions && primarySubdivisions.length > 0 && primarySubdivisions.map((primarySubdivision) => {
                    return (
                      <MenuItem className={clsx(`${primarySubdivision.subdivisionName}`)} key={primarySubdivision.subdivisionID} value={primarySubdivision.subdivisionID}>
                        {primarySubdivision.subdivisionName}
                      </MenuItem>
                    );
                  })}
                  {!formikProps.values['PrimaryCountryID'] &&
                    <MenuItem className={clsx(`select-a-country`)} value=''>
                      Select a country
                    </MenuItem>
                  }
                </Field>
                {textfield("PrimaryZip", "Zip *", formikProps)}
                {phonefield("PrimaryPhone1", "Phone 1 *", formikProps)}
                {phonefield("PrimaryPhone2", "Phone 2", formikProps)}
                {textfield(
                  "PrimaryEMailAddress",
                  "eMail Address *",
                  formikProps
                )}
              </Box>

              <Box
                className={style.grouping}
                borderColor="grey.main"
                border={1}
              >
                Secondary Contact
                {textfield("SecondaryFirstName", "First Name", formikProps)}
                {textfield("SecondaryLastName", "Last Name", formikProps)}
                {textfield("SecondaryAddress1", "Address 1", formikProps)}
                {textfield("SecondaryAddress2", "Address 2", formikProps)}
                <Field
                  id='secondarycountry'
                  data-id="secondary-country-select"
                  data-testid='secondary-country-textfield'
                  component={TextField}
                  name='SecondaryCountryID'
                  labelId='SecondaryCountryLabel'
                  label='Country'
                  variant='outlined'
                  value={formikProps.values['SecondaryCountryID']}
                  select
                  SelectProps={{
                    SelectDisplayProps: { 'data-testid': 'secondary-country-select' }
                  }}
                  onChange={(event, selected) => {
                    onSecondaryCountryChanged(formikProps, selected.props.value);
                  }}
                  size='small'
                >
                  {countries && countries.length > 0 && countries.map((country) => {
                    return (
                      <MenuItem className={clsx(`${countries.countryName}`)} key={country.countryID} value={country.countryID}>
                        {country.countryName}
                      </MenuItem>
                    );
                  })}
                </Field>
                {textfield("SecondaryCity", "City", formikProps)}
                <Field
                  id='secondarysubdivision'
                  data-id="secondary-subdivision-select"
                  data-testid='secondary-subdivision-textfield'
                  component={TextField}
                  name='SecondarySubdivisionID'
                  labelId='SecondarySubdivisionLabel'
                  label={secondarySubdivisionLabel}
                  variant='outlined'
                  value={formikProps.values['SecondarySubdivisionID']}
                  select
                  SelectProps={{
                    SelectDisplayProps: { 'data-testid': 'secondary-subdivision-select' }
                  }}
                  onChange={(event, selected) => {
                    const { setFieldValue } = formikProps;
                    setFieldValue('SecondarySubdivisionID', selected.props.value);
                  }}
                  size='small'
                >
                  {formikProps.values['SecondaryCountryID'] && secondarySubdivisions && secondarySubdivisions.length > 0 && secondarySubdivisions.map((secondarySubdivision) => {
                    return (
                      <MenuItem className={clsx(`${secondarySubdivision.subdivisionName}`)} key={secondarySubdivision.subdivisionID} value={secondarySubdivision.subdivisionID}>
                        {secondarySubdivision.subdivisionName}
                      </MenuItem>
                    );
                  })}
                  {!formikProps.values['SecondaryCountryID'] &&
                    <MenuItem className={clsx(`select-a-country`)} value=''>
                      Select a country
                    </MenuItem>
                  }
                </Field>
                {textfield("SecondaryZip", "Zip", formikProps)}
                {phonefield("SecondaryPhone1", "Phone 1", formikProps)}
                {phonefield("SecondaryPhone2", "Phone 2", formikProps)}
                {textfield(
                  "SecondaryEMailAddress",
                  "eMail Address",
                  formikProps
                )}
              </Box>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

FacilityEditor.defaultProps = {
  props: {},
  onSubmitComplete: () => { },
  onDeleteComplete: () => { },
  onAddChildrenComplete: () => { },
  onCancel: () => { }
};

FacilityEditor.propTypes = {
  props: PropTypes.shape({
    entityID: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    parentEntityID: PropTypes.string
  }),
  onSubmitComplete: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onCancel: PropTypes.func,
  functions: PropTypes.shape({
    create: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func
  })
};

export default FacilityEditor;
