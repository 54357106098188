import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Grid, TextField } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { entityCreate, entityUpdate } from "../../../state/slices/entities";
import { updateEntity, createNewEntity } from "../../../state/slices/CoreEntity";
import * as Yup from "yup";
import PropTypes from "prop-types";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Title from "../../Title";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./FacilityGroupEditor.styles";
import { ENTITY_TYPE } from "../../../constants";
import apiClient from "../../../auth/apiClient";
import EntityService from "../../../services/EntityService";
import clsx from "clsx";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

export const validationSchema = Yup.object().shape({
  facilityGroupName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Cannot exceed 100 characters")
    .required("Required"),
});

const FacilityGroupEditor = ({
  entityID,
  parentEntityID,
  onSubmitComplete,
  onCancel,
}) => {
  const classes = styles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const useCoreEntitySlice = useCoreEntityContext();

  const dispatch = useDispatch();
  const entityService = new EntityService(apiClient);

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const fetchEntity = async () => {
      const response = await entityService.getEntity(null, entityID);
      if (response?.data) {
        setValue("facilityGroupName", response.data.entityName);
      }
    };

    if (entityID) {
      fetchEntity(entityID);
    }
  }, [entityID]);

  const onFormValueChange = (e) => {
    setValue(e.target.name, e.target.value.trim());
  };

  const createOrUpdateEntity = async (data) => {
    const payload = {
      ParentEntityId: parentEntityID,
      EntityType: ENTITY_TYPE.FacilityGroup,
      EntityName: data.facilityGroupName,
    };
    let result;

    if (entityID) {
      payload.EntityId = entityID;
      result = await dispatch(
          useCoreEntitySlice ?
            updateEntity({
              path: "entities/basic",
              entity: payload,
            })
            :
            entityUpdate({
              path: "entities/basic",
              entity: payload,
            })
      );
    } else {
      result = await dispatch(
          useCoreEntitySlice ?
            createNewEntity({
              path: "entities/basic",
              entity: payload,
            })
            :
            entityCreate({
              path: "entities/basic",
              entity: payload,
            })
      );
    }

    if (result?.error) {
      enqueueSnackbar(
        `We encountered an error while ${
          entityID ? "updating" : "creating"
        } the facility group`,
        { variant: "error", tag: "FailedToUpdateFacilityGroup" }
      );
      return;
    }

    onSubmitComplete();
  };

  return (
    <>
      <div data-testid="FacilityGroupEditorTitle">
        <Title>
          <span className={classes.camelCase}>
            {entityID ? "Edit Facility Group" : "Create Facility Group"}
          </span>
        </Title>
      </div>

      <Divider className={classes.divider}></Divider>

      <form
        onSubmit={handleSubmit(
          async (data) => await createOrUpdateEntity(data)
        )}
      >
        <Controller
          name="facilityGroupName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              data-testid="facilityGroupNameField"
              id="facilityGroupNameField"
              variant="outlined"
              margin="normal"
              fullWidth
              type="text"
              label="Name"
              autoFocus
              onBlur={onFormValueChange}
              error={!!errors.facilityGroupName}
              helperText={
                errors.facilityGroupName && errors.facilityGroupName.message
              }
            />
          )}
        />
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            data-testid="submitButton"
            className={clsx(classes.submitButton, "save")}
            type="submit"
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {entityID ? "Update" : "Create"}
          </Button>
          <Button
            startIcon={<CancelIcon />}
            variant="contained"
            onClick={onCancel}
            className={clsx("cancel")}
          >
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

FacilityGroupEditor.defaultProps = {
  props: {},
  onSubmitComplete: () => {},
  onCancel: () => {},
};

FacilityGroupEditor.propTypes = {
  props: PropTypes.shape({
    entityID: PropTypes.string,
    name: PropTypes.string,
    parentEntityID: PropTypes.string,
  }),
  onSubmitComplete: PropTypes.func,
  onCancel: PropTypes.func,
  functions: PropTypes.shape({
    create: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
  }),
};
export default FacilityGroupEditor;
